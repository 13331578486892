import axios from "axios";
import React, { Component } from "react";
import WorkInProgress from "./work_in_progress";

class KnowledgeBase extends Component {
  state = {
    loca: this.props.loca,
    loading: false,
    categories: [],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getKnowledgeBase();
  }

  getKnowledgeBase = () => {
    this.setState({ loading: true });
    var token = localStorage.getItem("token");
    axios
      .get(this.state.loca + "/loom/get/knowledgeBase", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const rvrecord = resp.data.knowledgeBase;
          if ("Error" in rvrecord) {
            this.setState({
              loading: false,
            });
          } else {
            this.setState({
              loading: false,
              categories: rvrecord,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  selectFn = (e) => {
    this.callKnowledge(e);
  };

  callKnowledge = (name) => {
    const { navigateWithSearchParams } = this.props;
    navigateWithSearchParams(
      "/knowledgeCategory",
      { state: { value: name } },
      {}
    );
    // navigation("/knowledgeCategory", { state: { value: name } });
  };

  render() {
    return (
      <div>
        {this.state.loading === true ? (
          <div>
            <div className="g-compo-loader2">
              <WorkInProgress></WorkInProgress>
            </div>
          </div>
        ) : (
          <div className="pagesetup">
            <div className="card p-3" style={{ display: "flex" }}>
              <div className="container justify-content-center">
                <select
                  onChange={(e) => {
                    this.selectFn(e.target.value);
                  }}
                >
                  <option value={""}>{"All"}</option>
                  {this.state.categories.length > 0 &&
                    this.state.categories.map((obj, ind) => (
                      <option key={ind} value={obj.value}>
                        {obj.name}
                      </option>
                    ))}
                </select>
              </div>
              <div>
                <h2 className="m-4">Knowledge Bases</h2>
              </div>
              {this.state.categories.length > 0 &&
                this.state.categories.map((obj, ind) => (
                  <div
                    className="card w-75"
                    onClick={() => this.callKnowledge(obj.name)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card-body">
                      <h5 className="card-title" key={ind} value={obj.value}>
                        {obj.name}
                      </h5>
                      <p className="card-text">
                        {obj.articleSize + " Articles"}{" "}
                      </p>
                    </div>
                  </div>
                ))}
              {/* <div className="mt-4 card card-body">
                  {knowledgeBases.length > 0 &&
                    knowledgeBases.map((obj, ind) => <div key={ind}></div>)}
                </div> */}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default KnowledgeBase;
