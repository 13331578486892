import React from 'react';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

const WithNavigate = (Component) => {
    const WithNavigateComponent  = (props) => {
        const navigate = useNavigate();
        const location = useLocation();
        

       /*  console.log('WithNavigate: location', location); */
        const navigateWithSearchParams = (pathname, params, state) => {

          //selectedpolicy?id=34f486cd-d04c-493d-b9c3-79e6e8d77f6b&name=Privacy
          console.log("pathname",pathname, params, state);
          
          navigate({
            pathname,
            search: createSearchParams(params).toString(),
          }, { state });
        };
        return <Component {...props} navigate={navigate} navigateWithSearchParams={navigateWithSearchParams} location={location} />;
      };
      return WithNavigateComponent;
  };

export default WithNavigate;
