import React, { Component } from "react";
import "../css/login.css";
import axios from "axios";
import WorkInProgress from "./work_in_progress";
import WithNavigate from "./withNavigate";
import GlobalContext from "./GlobalContext ";
import { isMobile } from "react-device-detect";

class Login extends Component {
  static contextType = GlobalContext;

  state = {
    resp: [],
    showlogin: false,
    callDash: false,
    loading: false,
    language: this.props.language,
    username: "",
    password: "",
    pageError: false,
    error: "",
    pageMessage: false,
    message:
      "Successfully Registered. You will receive login username and password via email or sms after approval within a 48 hours. Thank you!",
    setUserInfo: {},
    loca: this.props.loca,
    showHidePassword: "fa fa-eye-slash hidepassword",
    flag: true,
    registered: "",
    textTr: "",
    // isExpotokenInit: false,
  };

  _isMounted = false;

  constructor(props) {
    super(props);
    this.username = React.createRef();
    this.password = React.createRef();

    this.logincall = this.logincall.bind(this);
    this.showRegister = this.showRegister.bind(this);
    this.callForgetPassword = this.callForgetPassword.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.callNewPassword = this.callNewPassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.hideShowPassword = this.hideShowPassword.bind(this);
  }

  async componentDidMount() {
    this.setState({ pageMessage: false, message: "" });
    let reg = this.state.registered;
    let tab_name = localStorage.getItem("tableName");
    let mobile = false;
    if (window.innerWidth <= 480) {
      this.setState({ isMobile: true });
    }
    if (reg !== null && reg !== undefined) {
      if (reg === true || reg === "true") {
        if (tab_name === "client_new") {
          this.setState({
            pageMessage: true,
            message:
              "Successfully Registered. You will receive login username and password via email or sms after approval within a 48 hours. Thank you!",
          });
        } else if (tab_name === "loom_registration") {
          this.setState({
            pageMessage: true,
            message:
              "Successfully Registered. You will receive login username and password via email or sms. Thank you!",
          });
        }
      }
    }

    localStorage.removeItem("mobile");
    // this.setState({ isMounted: true })
    this._isMounted = true;
    let rsp = await this.props.verifyRecord();

    if (rsp === "verified" || rsp === "refereshed") {
      // this.props.navigate("/private/dashboard");
      const { navigateWithSearchParams } = this.props;
      setTimeout(() => {
        navigateWithSearchParams("/private/dashboard", {}, {});
      }, 1000);
      document.title = "Dashboard";
    }
  }

  componentDidUpdate() {
    // this.setState({ pageMessage: false, message: "" });
  }

  componentWillUnmount() {
    // this.setState({ isMounted: false });
    // this.setState({ pageMessage: false, message: "" });
    this._isMounted = false;
  }

  static getDerivedStateFromProps(props, state) {
    let registered = props.location.state?.registered;
    if (props.isMobile !== state.isMobile) {
      return {
        isMobile: props.isMobile,
      };
    } else if (registered !== state.registered) {
      return {
        registered: registered,
      };
    }
    // registered=false;
    return null;
  }

  verifyRecord() {
    var token = localStorage.getItem("token");
    if (token !== "" && token !== null) {
      axios
        .get(this.state.loca + "/verify", {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          console.log("resssss: ", res.status);

          if (res.status === 200) {
            var userInfo = {
              userId: res.data[3].userId,
              username: res.data[3].username,
              name: res.data[3].name,
              admin: res.data[3].admin,
              cid: res.data[3].cid,
              mobileNumber: res.data[3].mobileNumber,
            };
            var rsp = res.data[0].response;
            if (rsp === "verified" || rsp === "refereshed") {
              console.log("vrrrrrrrrr");
              if (rsp === "verified") {
                console.log("v");
                if (this._isMounted) {
                  this.setState({ showlogin: false });
                }
                localStorage.setItem("userDetails", JSON.stringify(userInfo));
                this.dismiss();
              } else if (rsp === "refereshed") {
                console.log("r");
                localStorage.setItem("token", res.data[1].token);
                this.setState({ showlogin: false });
                localStorage.setItem("userDetails", JSON.stringify(userInfo));
                this.dismiss();
              }
            } else if (rsp === "fail" || rsp === "not_verified") {
              console.log("nnnnv");
              localStorage.setItem("token", "");
              this.setState({ showlogin: true });
            }
          } else {
            this.setState({ showlogin: true });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              localStorage.setItem("token", "");
              if (
                error.response.data.cause.startsWith(
                  "io.jsonwebtoken.ExpiredJwtException: JWT expired at "
                )
              ) {
                this.setState({ showlogin: true });
              }
              this.setState({ showlogin: true });
            }
          } else if (error.request) {
          } else {
          }
        });
    } else {
      this.setState({ showlogin: true });
    }
  }

  dismiss() {
    if (this._isMounted) {
      this.setState({ loading: false });
    }
    this.props.unmountMe();
    // this.props.showDash();
    this.props.showMainCompo(JSON.parse(localStorage.getItem("userDetails")));
  }

  showRegister(language, type) {
    // this.props.showRegister(language, type);
    const { navigateWithSearchParams } = this.props;
    // this.props.navigate("/clientregister")
    navigateWithSearchParams("/register", {}, { language, type });
    document.title = "Register";
  }

  callForgetPassword() {
    this.props.showForgetPassword();
    this.props.navigate("/forgetPassword");
  }

  callPolicy() {
    this.props.navigate("/policy");
  }

  callNewPassword(op, un) {
    const { navigateWithSearchParams } = this.props;
    // this.props.showNewPassword(op, un);
    navigateWithSearchParams("/setpassword", {}, { op, un });
  }

  logincall() {
    // if (this.username.value === "" && this.password.value === "") {
    if (this.state.username === "" && this.password.value === "") {
      this.setState({ pageError: true, error: "Check username and password!" });
      // } else if (this.username.value === "") {
    } else if (this.state.username === "") {
      this.setState({ pageError: true, error: "Check username and password!" });
    } else if (this.password.value === "") {
      this.setState({ pageError: true, error: "Check username and password!" });
    } else {
      this.setState({ loading: true });
      axios
        .post(
          this.state.loca + "/authenticate",
          {
            //     username: this.username.value.trim(),
            username: this.state.username?.trim?.(),
            password: this.password.value.trim(),
            application: "loomyarn",
          },
          {
            headers: {
              // "Content-Type": "application/json",
              // accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log("res: ", res.status);
          if (res.status === 200) {
            if (res.data.authenticated === true) {
              var token = res.data.token;
              var userInfo = {
                userId: res.data.userId,
                username: res.data.username,
                name: res.data.name,
                admin: res.data.admin,
                cid: res.data.cid,
                mobileNumber: res.data.mobileNumber,
              };
              localStorage.setItem("userDetails", JSON.stringify(userInfo));
              if (res.data.passwordReset) {
                // this.callNewPassword(this.password.value, this.username.value);
                this.callNewPassword(this.password.value, this.state.username);
                //    this.props.navigate("/private")
              } else {
                if (token != null) {
                  const { navigateWithSearchParams } = this.props;
                  localStorage.setItem("token", token);
                  this.props.dismiss();
                  this.props.verifyRecord();
                  // this.props.navigate("/private");
                  setTimeout(() => {
                    navigateWithSearchParams("/private", {}, {});
                  }, 1000);
                }
              }
            } else {
              this.setState({
                error: "Check username and password!",
                loading: false,
                pageError: true,
              });
            }
          }
        });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  setLanguage(val) {
    this.setState({
      language: val,
    });
  }

  hideShowPassword() {
    if (!this.state.flag) {
      this.setState({
        showHidePassword: "fa fa-eye-slash hidepassword",
        flag: !this.state.flag,
      });
    } else {
      this.setState({
        showHidePassword: "fa fa-eye hidepassword",
        flag: !this.state.flag,
      });
    }
  }

  setFlag(e) {
    this.setState({ flag: !e.target.checked });
  }

  formChange = (type, val) => {
    if (type === "username") {
      this.setState({ username: val?.toLowerCase?.() });
    }

    if (this.state.pageError === true) {
      this.setState({ pageError: false, error: "" });
    }
  };

  render() {
    const language = this.context;
    return (
      <>
        <div>
          {this.state.loading === true && (
            <div>
              <WorkInProgress></WorkInProgress>
            </div>
          )}
          <div>
            {this.props.showlogin === true && (
              <div>
                {window.innerWidth <= 480 ? (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      overflowY: "hidden",
                      overflowX: "hidden",
                      minHeight: "80vh",
                      minWidth: "100vw",
                      flexDirection: "column",
                    }}
                  >
                    {this.state.pageMessage === true && (
                      <div
                        className="alert alert-success reg-message"
                        role="alert"
                        style={{ color: "black" }}
                      >
                        {this.state.message}
                        <button
                          onClick={() => this.setState({ pageMessage: false })}
                          className="cancel-icon"
                          aria-label="Close alert"
                        >
                          &times; {/* This represents the 'X' icon */}
                        </button>
                      </div>
                    )}

                    <div className="container px-0">
                      <div className="d-flex justify-content-center h-100">
                        <div
                          className="card_log rounded" /*style={{ marginTop: "3.5rem" }}*/
                        >
                          <div className="card-header lang">
                            {this.state.pageError === true && (
                              <div
                                className="alertgp alert-danger"
                                style={{ color: "black" }}
                              >
                                {this.state.error}
                              </div>
                            )}
                            <h3 className="cen">
                              {this.state.language === "English"
                                ? "Sign In"
                                : this.state.language === "Hindi"
                                ? "साइन इन"
                                : this.state.language === "Urdu"
                                ? "سائن ان"
                                : "Sign In"}
                            </h3>
                            <div className="justify-content-start cen_set">
                              <span className="pad5" style={{ color: "white" }}>
                                {this.state.language === "English"
                                  ? "Language"
                                  : this.state.language === "Hindi"
                                  ? "भाषा"
                                  : this.state.language === "Urdu"
                                  ? "زبان"
                                  : "Language"}{" "}
                              </span>
                              <select
                                value={this.state.language}
                                className="form-control form-select"
                                style={{
                                  marginLeft: "5px",
                                  lineHeight: "1.5",
                                  width: "50%",
                                }}
                                onChange={(e) => {
                                  this.setLanguage(e.target.value);
                                }}
                              >
                                <option value={"English"}>
                                  {this.state.language === "English"
                                    ? "English"
                                    : this.state.language === "Hindi"
                                    ? "अंग्रेज़ी"
                                    : this.state.language === "Urdu"
                                    ? "انگریزی"
                                    : "English"}
                                </option>
                                <option value={"Hindi"}>
                                  {this.state.language === "English"
                                    ? "Hindi"
                                    : this.state.language === "Hindi"
                                    ? "हिन्दी"
                                    : this.state.language === "Urdu"
                                    ? "ہندی"
                                    : "Hindi"}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="card-body cen_set">
                            <div className="input-group form-group pb-2">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i
                                    className=" ftsize fa fa-user"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Profile"
                                  ></i>
                                </span>
                              </div>
                              <input
                                type="text"
                                name="username"
                                placeholder={
                                  this.state.language === "English"
                                    ? "Username /Mobile no"
                                    : this.state.language === "Hindi"
                                    ? "उपयोगकर्ता नाम"
                                    : this.state.language === "Urdu"
                                    ? "صارف نام"
                                    : "Username"
                                }
                                className="form-control"
                                /*   style={{ textTransform: "lowercase" }} */
                                /*         ref={(username) => {
                          
                                  this.username = username?.toLowerCase?.();
                                }} */

                                value={this.state.username}
                                onChange={(e) => {
                                  this.formChange("username", e.target.value);
                                }}
                              ></input>
                            </div>
                            <div className="input-group form-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="ftsize fa fa-key"></i>
                                </span>
                              </div>
                              <input
                                type={this.state.flag ? "password" : "text"}
                                name="password"
                                placeholder={
                                  this.state.language === "English"
                                    ? "Password"
                                    : this.state.language === "Hindi"
                                    ? "पासवर्ड"
                                    : this.state.language === "Urdu"
                                    ? "پاس ورڈ"
                                    : "Password"
                                }
                                className="form-control"
                                style={{
                                  borderTopRightRadius: "0.25rem",
                                  borderBottomRightRadius: "0.25rem",
                                }}
                                ref={(password) => {
                                  this.password = password;
                                }}
                                onChange={() => {
                                  this.formChange();
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    this.logincall();
                                  }
                                }}
                              ></input>
                              <div className="input-group-prepend">
                                <i
                                  className={this.state.showHidePassword}
                                  style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "4%",
                                    zIndex: "3",
                                  }}
                                  aria-hidden="true"
                                  onClick={this.hideShowPassword}
                                ></i>
                              </div>
                            </div>
                            <span
                              className="lin w-100 forgot-password-position"
                              // d-flex justify-content-end
                              // style={{ marginLeft: "63.9px" }}
                              onClick={() => this.callForgetPassword()}
                            >
                              <a
                                className="lnk-color me-4"
                                style={{
                                  textDecoration: "none",
                                  cursor: "pointer",
                                }}
                              >
                                {this.state.language === "English"
                                  ? "Forgot password?"
                                  : this.state.language === "Hindi"
                                  ? "पासवर्ड भूल गए हैं? "
                                  : this.state.language === "Urdu"
                                  ? "اپنا پاس ورڈ بھول گئے؟"
                                  : "Forgot password?"}
                              </a>
                            </span>
                            {/* <div className="align-items-center remember">
                        {this.state.language === "English"
                          ? "Remember Me"
                          : this.state.language === "Hindi"
                            ? "मुझे याद रखें "
                            : this.state.language === "Urdu"
                              ? "مجھے یاد رکھنا"
                              : "Remember Me"}

                        <input type="checkbox" className="pos"></input>
                      </div> */}
                            {/* <div
                            className=" row align-items-center  justify-content-center remember "
                            style={{ fontSize: "16px", color: "white" }}
                          >
                            Show Password
                            <input
                              type="checkbox"
                              checked={!this.state.flag}
                              onChange={(e) => {
                                this.setFlag(e);
                              }}
                              aria-hidden="true"
                            />
                          </div> */}
                            <div className="form-group cen mobile-login-button-posi">
                              <input
                                type="button"
                                value={
                                  this.state.language === "English"
                                    ? "Login"
                                    : this.state.language === "Hindi"
                                    ? "लॉग इन"
                                    : this.state.language === "Urdu"
                                    ? "لاگ ان"
                                    : "Login"
                                }
                                className="btn float-right login_btn"
                                onClick={this.logincall}
                              ></input>
                            </div>
                          </div>
                          <div className="card-footer">
                            <div className="d-flex justify-content-center links">
                              {this.state.language === "English"
                                ? "Don't have an account?"
                                : this.state.language === "Hindi"
                                ? "खाता नहीं है?"
                                : this.state.language === "Urdu"
                                ? "اکاؤنٹ نہیں ہے؟"
                                : "Don't have an account?"}
                            </div>
                            <div className="d-flex justify-content-center links">
                              {/* <button
                              className="btn btnyellow"
                              onClick={() =>
                                this.showRegister(this.state.language, "user")
                              }
                            >
                              {this.state.language === "English"
                                ? "Sign Up for User"
                                : this.state.language === "Hindi"
                                  ? "उपयोगकर्ता के लिए साइन अप करें"
                                  : this.state.language === "Urdu"
                                    ? "صارف کے لیے سائن اپ کریں"
                                    : "Sign In"}
                            </button> */}
                              <button
                                className="btn btnyellow"
                                onClick={() =>
                                  this.showRegister(
                                    this.state.language,
                                    "client"
                                  )
                                }
                              >
                                {this.state.language === "English"
                                  ? "Sign Up For Client"
                                  : this.state.language === "Hindi"
                                  ? "ग्राहक के लिए साइन अप करें"
                                  : this.state.language === "Urdu"
                                  ? "کلائنٹ کے لیے سائن اپ کریں"
                                  : "Sign In"}
                              </button>
                            </div>
                            {/* <a
                              href=""
                              style={{ fontSize: "16px", color: "#0044a7" }}
                            >
                              Privacy Policy
                            </a> */}
                            {/* <div className="d-flex justify-content-center links">
                            <button
                              className="btn btnyellow"
                              style={{ marginTop: "1%" }}
                              onClick={() => this.callForgetPassword()}
                            >
                              {this.state.language === "English"
                                ? "Forgot your password?"
                                : this.state.language === "Hindi"
                                  ? "पासवर्ड भूल गए हैं? "
                                  : this.state.language === "Urdu"
                                    ? "اپنا پاس ورڈ بھول گئے؟"
                                    : "Forgot your password?"}
                            </button>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      className="flex-center"
                      style={{
                        minHeight: "90vh",
                        minWidth: "100vw",
                        flexDirection: "column",
                        overflow: "hidden",
                      }}
                    >
                      <div>
                        {this.state.pageMessage === true && (
                          <div
                            className="alert alert-success reg-message"
                            role="alert"
                            style={{ color: "black" }}
                          >
                            {this.state.message}
                            <button
                              onClick={() =>
                                this.setState({ pageMessage: false })
                              }
                              className="cancel-icon"
                              aria-label="Close alert"
                            >
                              &times; {/* This represents the 'X' icon */}
                            </button>
                          </div>
                        )}
                        <div className="d-flex justify-content-center h-100 w-100">
                          <div className="card_log card_log_mar  rounded">
                            <div className="card-header lang">
                              {this.state.pageError === true && (
                                <div
                                  className="alertgp alert-danger cen"
                                  style={{ color: "black" }}
                                >
                                  {this.state.error}
                                </div>
                              )}
                              <h3 className="cen">
                                {this.state.language === "English"
                                  ? "Sign In"
                                  : this.state.language === "Hindi"
                                  ? "साइन इन"
                                  : this.state.language === "Urdu"
                                  ? "سائن ان"
                                  : "Sign In"}
                              </h3>
                            </div>

                            <div className="card-body cen_set-screen ">
                              <div className="text-start mb-2">
                                <span className="" style={{ color: "white" }}>
                                  {this.state.language === "English"
                                    ? "Language"
                                    : this.state.language === "Hindi"
                                    ? "भाषा"
                                    : this.state.language === "Urdu"
                                    ? "زبان"
                                    : "Language"}{" "}
                                </span>
                                <select
                                  value={this.state.language}
                                  className="form-control form-select"
                                  style={{
                                    lineHeight: "1.3",
                                    width: "50%",
                                  }}
                                  onChange={(e) => {
                                    this.setLanguage(e.target.value);
                                  }}
                                >
                                  <option value={"English"}>
                                    {this.state.language === "English"
                                      ? "English"
                                      : this.state.language === "Hindi"
                                      ? "अंग्रेज़ी"
                                      : this.state.language === "Urdu"
                                      ? "انگریزی"
                                      : "English"}
                                  </option>
                                  <option value={"Hindi"}>
                                    {this.state.language === "English"
                                      ? "Hindi"
                                      : this.state.language === "Hindi"
                                      ? "हिन्दी"
                                      : this.state.language === "Urdu"
                                      ? "ہندی"
                                      : "Hindi"}
                                  </option>
                                </select>
                              </div>
                              <div className="input-group form-group pb-2">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i
                                      className=" ftsize fa fa-user "
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title="Profile"
                                    ></i>
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  name="username"
                                  placeholder={
                                    this.state.language === "English"
                                      ? "Username /Mobile No"
                                      : this.state.language === "Hindi"
                                      ? "उपयोगकर्ता नाम"
                                      : this.state.language === "Urdu"
                                      ? "صارف نام"
                                      : "Username"
                                  }
                                  className="form-control"
                                  /*   style={{ textTransform: "lowercase" }} */

                                  // ref={(username) => {
                                  //   this.username = username?.toLowerCase?.();
                                  // }}
                                  value={this.state.username}
                                  onChange={(e) => {
                                    this.formChange("username", e.target.value);
                                  }}
                                ></input>
                              </div>
                              <div className="input-group form-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="ftsize fa fa-key"></i>
                                  </span>
                                </div>
                                <input
                                  type={this.state.flag ? "password" : "text"}
                                  name="password"
                                  placeholder={
                                    this.state.language === "English"
                                      ? "Password"
                                      : this.state.language === "Hindi"
                                      ? "पासवर्ड"
                                      : this.state.language === "Urdu"
                                      ? "پاس ورڈ"
                                      : "Password"
                                  }
                                  className="form-control"
                                  style={{
                                    borderTopRightRadius: "0.25rem",
                                    borderBottomRightRadius: "0.25rem",
                                  }}
                                  ref={(password) => {
                                    this.password = password;
                                  }}
                                  onChange={() => {
                                    this.formChange();
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      this.logincall();
                                    }
                                  }}
                                ></input>
                                <div className="input-group-prepend">
                                  <i
                                    className={this.state.showHidePassword}
                                    style={{
                                      position: "absolute",
                                      top: "5px",
                                      right: "4%",
                                      zIndex: "3",
                                    }}
                                    aria-hidden="true"
                                    onClick={this.hideShowPassword}
                                  ></i>
                                </div>
                              </div>

                              <span
                                className="lin forgot-password-position"
                                // d-flex justify-content-end
                                onClick={() => this.callForgetPassword()}
                              >
                                <a
                                  className="lnk-color me-2"
                                  style={{
                                    textDecoration: "none",
                                    cursor: "pointer",
                                  }}
                                >
                                  {this.state.language === "English"
                                    ? "Forgot password?"
                                    : this.state.language === "Hindi"
                                    ? "पासवर्ड भूल गए हैं? "
                                    : this.state.language === "Urdu"
                                    ? "اپنا پاس ورڈ بھول گئے؟"
                                    : "Forgot password?"}
                                </a>
                              </span>
                              {/* <div className="align-items-center remember">
                          {this.state.language === "English"
                            ? "Remember Me"
                            : this.state.language === "Hindi"
                              ? "मुझे याद रखें "
                              : this.state.language === "Urdu"
                                ? "مجھے یاد رکھنا"
                                : "Remember Me"}

                          <input type="checkbox" className="pos"></input>
                        </div> */}
                              {/* <div
                          className=" row align-items-center  justify-content-center remember "
                          style={{ fontSize: "16px", color: "white" }}
                        >
                         {this.state.language === "English"
                            ? "Show Password?"
                            : this.state.language === "Hindi"
                              ? "पासवर्ड दिखाए"
                              : this.state.language === "Urdu"
                                ? "پاسورڈ دکھاو"
                                : "Show Password"}
                          <input
                            type="checkbox"
                            checked={!this.state.flag}
                            onChange={(e) => {
                              this.setFlag(e);
                            }}
                            aria-hidden="true"
                          />
                        </div> */}
                              <div className="form-group cen mobile-login-button-posi">
                                <input
                                  type="button"
                                  value={
                                    this.state.language === "English"
                                      ? "Login"
                                      : this.state.language === "Hindi"
                                      ? "लॉग इन"
                                      : this.state.language === "Urdu"
                                      ? "لاگ ان"
                                      : "Login"
                                  }
                                  className="btn float-right login_btn"
                                  onClick={this.logincall}
                                ></input>
                              </div>
                            </div>
                            <div className="card-footer">
                              <div className="d-flex justify-content-center links">
                                {this.state.language === "English"
                                  ? "Don't have an account?"
                                  : this.state.language === "Hindi"
                                  ? "खाता नहीं है?"
                                  : this.state.language === "Urdu"
                                  ? "اکاؤنٹ نہیں ہے؟"
                                  : "Don't have an account?"}
                              </div>
                              <div className="d-flex justify-content-center links">
                                {/* <button
                            className="btn btnyellow"
                            onClick={() =>
                              this.showRegister(this.state.language, "user")
                            }
                          >
                            {this.state.language === "English"
                              ? "Sign Up for User"
                              : this.state.language === "Hindi"
                                ? "उपयोगकर्ता के लिए साइन अप करें"
                                : this.state.language === "Urdu"
                                  ? "صارف کے لیے سائن اپ کریں"
                                  : "Sign In"}
                          </button> */}
                                <button
                                  className="btn btnyellow"
                                  onClick={() =>
                                    this.showRegister(
                                      this.state.language,
                                      "client"
                                    )
                                  }
                                >
                                  {this.state.language === "English"
                                    ? "Sign Up For Client"
                                    : this.state.language === "Hindi"
                                    ? "ग्राहक के लिए साइन अप करें"
                                    : this.state.language === "Urdu"
                                    ? "کلائنٹ کے لیے سائن اپ کریں"
                                    : "Sign In"}
                                </button>
                              </div>
                              {/* <a
                                href=""
                                style={{ fontSize: "16px", color: "#0044a7" }}
                              >
                                Privacy Policy
                              </a> */}
                              {/* <div className="d-flex justify-content-center links">
                          <button
                            className="btn btnyellow"
                            style={{ marginTop: "1%" }}
                            onClick={() => this.callForgetPassword()}
                          >
                            {this.state.language === "English"
                              ? "Forgot your password?"
                              : this.state.language === "Hindi"
                              ? "पासवर्ड भूल गए हैं? "
                              : this.state.language === "Urdu"
                              ? "اپنا پاس ورڈ بھول گئے؟"
                              : "Forgot your password?"}
                          </button>
                        </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="bdr_btm"></div>
                <div className="fotr_prop underline">
                  <span onClick={() => this.callPolicy()}>Privacy Policy</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default WithNavigate(Login);
