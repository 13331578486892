import React, { Component } from 'react';
import axios from 'axios';
import WithNavigate from "./withNavigate";

class PolicyPage extends Component {
    state = { 
        loca: this.props.loca,
        policyData: "",
        screenWidth : window.screen.width,
     } 

     constructor(props){
        super(props);
        
     }

     componentDidMount(){
        this.getPolicyData();
     }

      truncateHTMLString = (htmlString, maxLength) => {
        const trimmedString = htmlString.replace(/(<([^>]+)>)/gi, ""); // Remove HTML tags
        if (trimmedString.length > maxLength) {
          const truncatedString = trimmedString.substring(0, maxLength); // Truncate the string
          const truncatedHTML = truncatedString + "...";
          return truncatedHTML.replace(/\\n|\\/g, '');
        } else {
          return htmlString; // Return original HTML if it doesn't need truncation
        }
      };

       getPolicyData = () => {
        var token = localStorage.getItem("token");
        if (token === null || token === "") {
          axios
          .get(this.state.loca + `/loom/get/publicknowledge/${1}/${1}`, {
          })
          .then((resp) => {
            const respData = resp.data;
            console.log("respData", respData);
            
            this.setState({policyData: respData.knowledge});
          })
          .catch((error) => {
            console.error(error);
          });
        } else {
          axios
          .get(this.state.loca + `/loom/get/Allknowledge/${1}/${1}`, {
            headers: {
                authorization: "Bearer " + token,
            },
          })
          .then((resp) => {
            const respData = resp.data;
            console.log("respData", respData);
            
            this.setState({policyData: respData.knowledge});
          })
          .catch((error) => {
            console.error(error);
          });
        }
      };

      goToSelectedPolicy = (obj) => {
        var token = localStorage.getItem("token");
        const { navigateWithSearchParams } = this.props;
        if (token === null || token === "") {
          navigateWithSearchParams(
            `/selectedpolicy`,{id:obj.id,name:obj.name},
            {
              state: {
                data: obj,
              },
            }
          );
        } else {
          navigateWithSearchParams(
            `/private/selectedpolicy`,{id:obj.id,name:obj.name},
            {
              state: {
                data: obj,
              },
            }
          );
        }
        
      }

    render() { 
        return (
            <div>
            <div
              className="d-flex align-items-center mt-2 mb-4 border-bottom"
              style={{ justifyContent: "start", width: "100%" }}
            >
              <div
                className="new-navbar-logo ms-2 text-start"
                style={
                  this.state.screenWidth < 600 ? { display: "none" } : { position: "absolute" }
                }
              >
                <span style={{fontSize:"1.5rem", fontWeight:"bold"}}>LoomYarn</span>
                {/* <NavLink
                  onClick={() => setOverlay(false)}
                  className="navbar-brand cursor-pointer"
                  to="/"
                >
                  <img
                    className="mufama-logo"
                    src={imageloca + appLogo}
                    alt="Mufama Logo"
                  />
                </NavLink> */}
              </div>
              <div
                className={
                  this.state.screenWidth < 600 ? "col text-center ms-1 mt-1 " : "text-center"
                }
                style={
                    this.state.screenWidth < 600 ? {} : { width: "100%", margin: "0px auto" }
                }
              >
                <h1>Policies</h1>
              </div>
            </div>
            <div className="center-flex">
              <div style={this.state.screenWidth>500?{width:"85%"}:{}}>
                <div
                  style={{
                    display: "grid",
                    // gridTemplateColumns: "repeat(auto-fit, minmax(30%, 1fr))",
                    gridTemplateColumns: `${
                        this.state.screenWidth > 500
                        ? "repeat(auto-fit, minmax(30%, 1fr))"
                        : "repeat(auto-fit, minmax(60%, 1fr))"
                    }`,
                    gap: "16px",
                    //   display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    //   width: "50%",
                  }}
                  className="mx-2 mb-4"
                >
                  {this.state.policyData &&
                   this.state.policyData.map((obj, index) => (
                      <div
                        key={index}
                        className="border p-2 center-flex"
                        style={{
                          flexDirection: "column",
                          width: "100%",
                          height: "100%",
                          // height: "180px",
                          wordWrap: "break-word",
                        }}
                        onClick={() => {
                          this.goToSelectedPolicy(obj);
                        }}
                      >
                        <div className="center-flex policy-content">
                          <h3>{obj.title}</h3>
                        </div>
                        <div>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.truncateHTMLString(obj.html, 200),
                              // __html:obj.html,
                            }}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        );
    }
}
 
export default WithNavigate(PolicyPage);
