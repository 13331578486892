import axios from "axios";
import React, { Component } from "react";
import WithNavigate from "./withNavigate";
import WorkInProgress from "./work_in_progress";

class KnowledgeCategoryCompo extends Component {
  state = {
    loca: this.props.loca,
    loading: false,
    categories: [],
    page_error: false,
    error: "",
    categories: [],
    record: [],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // this.getKnowledgeCategory(location.value);
  }

  updateSearchParams = (prev) => {
    let oldLocation = prev?.location;
    let newLocation = this.props.location;
    const { location } = this.props;
    const { value } = location.state;
    const searchParams = new URLSearchParams(location.search);
    let listName = searchParams.get("listName");
    // this.setState({ listName: this.state.listName, filter: fil });
  };

  getKnowledgeCategory = (categoryName) => {
    this.setState({ loading: true });
    var token = localStorage.getItem("token");
    axios
      .get(this.state.loca + "/loom/get/CategoryAndKnowledge/" + categoryName, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const rvrecord = resp.data.knowledgeCategory;
          if ("Error" in rvrecord) {
            this.setState({
              page_error: true,
              error: rvrecord.error,
              loading: false,
            });
          } else {
            this.setState({
              categories: rvrecord,
              record: rvrecord,
              loading: false,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  callKnowledge = (obj) => {
    const { navigateWithSearchParams } = this.props;
    navigateWithSearchParams("/knowledge", { state: { knowledge: obj } }, {});
    // navigation("/knowledge", { state: { knowledge: obj } })
  };

  modSearch = (nam) => {
    if (nam.length > 2) {
      var srch = this.state.categories;
      let xyz = [];
      for (let u = 0; u < srch.length; u++) {
        let search = srch[u];
        var abc = [];
        // for (var i = 0; i < search.length; i++) {
        var cat_name = search.categoryName.toLowerCase();
        if (cat_name.includes(nam.toLowerCase())) {
          abc.push({
            categoryName: search.categoryName,
            knowledge: search.knowledge,
          });
        } else {
          var cnt = 0;
          var kno = [];
          for (var j = 0; j < search.knowledge.length; j++) {
            var sec_m = search.knowledge[j].knowledgeName.toLowerCase();
            if (sec_m.includes(nam.toLowerCase())) {
              kno.push(search.knowledge[j]);
              cnt++;
            }
          }
          if (cnt > 0) {
            abc.push({
              categoryName: search.categoryName,
              knowledge: kno,
            });
          }
        }
        // }
        for (let i = 0; i < abc.length; i++) {
          xyz.push(abc[i]);
        }
      }
      this.setState({ record: xyz });
    } else if (nam === "") {
      var srch = this.state.categories;
      this.setState({ record: srch });
    }
  };

  render() {
    return (
      <div>
        {this.state.loading === true ? (
          <div>
            <div className="g-compo-loader2">
              <WorkInProgress></WorkInProgress>
            </div>
          </div>
        ) : (
          <div style={{ flexGrow: 1 }}>
            <div className="list-group ">
              <div className="card p-3" style={{ display: "flex" }}>
                <div id="sidebar" className="mb-2">
                  <input
                    className="form-control mr-sm-2 "
                    type="search"
                    placeholder="Search category ..."
                    aria-label="Search"
                    onChange={(e) => this.modSearch(e.target.value)}
                  ></input>
                </div>
                <div>
                  {this.state.record.length > 0 &&
                    this.state.record.map((obj, ind) => (
                      <ul key={ind}>
                        <li>
                          {obj.categoryName}
                          {obj.knowledge.length > 0 &&
                            obj.knowledge.map((objl, inx) => (
                              <div
                                key={inx}
                                style={{ cursor: "pointer" }}
                                onClick={() => this.callKnowledge(objl, ind)}
                              >
                                {objl.knowledgeName}
                              </div>
                            ))}
                        </li>
                      </ul>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default WithNavigate(KnowledgeCategoryCompo);
