import React, { Component } from "react";
import axios from "axios";
import AlertCompo from "./AlertCompo";
import "../css/AccountDeletePage.css";
import { AppConfig } from "../Utility/AppConfig";

class AccountDeletePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      pageAlert: false,
      alertMsg: "",
      alertType: "",
      flag: false,
      showpassword: "fa fa-eye-slash hidepassword-d",
      selectedReason: "",
      description: "",
      emailAddress: "retail.support@cloudsmaya.com",
      isMobile: false,
      isIPad: false,
      requested: false,
      loading: false,
      loca: AppConfig.loca,
      token: localStorage.getItem("token"),
    };
  }

  componentDidMount() {
    this.getDeleteStatus();
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isIPadDevice = /iPad|Macintosh/i.test(userAgent);

    this.setState({
      isMobile:
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        ) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1) ||
        (navigator.platform === "Win32" && "ontouchstart" in window) ||
        /Windows Phone/i.test(userAgent),
      isIPad: isIPadDevice,
    });
  }

  handleDescChange = (event) => {
    this.setState({
      pageAlert: false,
      description: event.target.value,
    });
  };

  handleChange = (event) => {
    this.setState({
      pageAlert: false,
      selectedReason: event.target.value,
    });
  };

  hideshowpassword = () => {
    this.setState((prevState) => ({
      showpassword:
        prevState.showpassword === "fa fa-eye-slash hidepassword-d"
          ? "fa fa-eye  hidepassword-d"
          : "fa fa-eye-slash hidepassword-d",
      flag: !prevState.flag,
    }));
  };
  handleEmailClick = () => {
    const { emailAddress, isMobile } = this.state;
    if (isMobile) {
      window.location.href = `mailto:${emailAddress}`;
    } else {
      const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
        emailAddress
      )}`;
      window.open(gmailLink, "_blank", "noopener noreferrer");
    }
  };

  setAlertFn = (type, desc, show) => {
    this.setState({
      alertType: type,
      alertMsg: desc,
      pageAlert: show,
    });
  };

  sendAccountDeleteRequest = () => {
    this.setState({
      pageAlert: false,
    });

    const { password, selectedReason, description } = this.state;

    if (!password) {
      this.setAlertFn("danger", "Please enter your password", true);
      return;
    }
    if (!selectedReason) {
      this.setAlertFn(
        "danger",
        "Please select a reason for account deletion",
        true
      );
    }
    if (description.length < 100) {
      this.setAlertFn(
        "danger",
        "Description should be at least 100 characters",
        true
      );
    }
    
    const jso = {
      password: this.state.password,
      reason: this.state.selectedReason,
      description: this.state.description,
    };
    axios
      .post(this.state.loca + "/loom/delete/account", jso, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + this.state.token,
        },
      })
      .then((resp) => {
        let data = resp.data;
        if (data.Success) {
          this.setState({
            requested: true,
          });
        } else {
          this.setAlertFn("danger", data.Error, true);
        }
      })
      .catch((error) => {
        this.setAlertFn("danger", error, true);
      });
  };

  getDeleteStatus = () => {
    axios
      .get(this.state.loca + "/loom/check/deletestatus", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + this.state.token,
        },
      })
      .then((resp) => {
        let data = resp.data;
        if (!data.Error) {
          if (data.Message === "Requested") {
            this.setState({
              requested: true,
            });
          }
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setAlertFn("danger", error, true);
      });
  };
  render() {
    const {
      password,
      pageAlert,
      alertMsg,
      alertType,
      flag,
      showpassword,
      selectedReason,
      description,
      emailAddress,
      requested,
      loading,
    } = this.state;
    if (!loading) {
      if (requested) {
        return (
          <div className="container my-3">
            <div className="card">
              <div className="card-header">
                <h4 className="my-1">Account deletion request received</h4>
              </div>
              <div className="card-body">
                <h5 className="text-start mb-4">
                  We have received your request to delete your account account.
                  Our team will now start the review process.
                </h5>
                <ul>
                  <li>
                    <h6 className="text-start">Reviewing Account Activities</h6>
                    <p className="text-start">
                      Our team will review all your account activities to ensure
                      that there are no incomplete transactions, such as pending
                      payments or refunds.
                    </p>
                  </li>
                  <li>
                    <h6 className="text-start">
                      Clearing Pending Transactions
                    </h6>
                    <p className="text-start">
                      If any incomplete transactions are found, we will work on
                      clearing them. This includes processing any pending
                      payments or issuing refunds.
                    </p>
                  </li>
                  <li>
                    <h6 className="text-start">Account Deletion Scheduled</h6>
                    <p className="text-start">
                      Your account is scheduled to be deleted 24 hours from now.
                      During this time, you will still have access to your
                      account in case you change your mind.
                    </p>
                  </li>
                  <li>
                    <h6 className="text-start">Data Deletion</h6>
                    <p className="text-start">
                      Once the 24-hour period has passed and all transactions
                      are cleared, your account and all associated data will be
                      deleted from our system an you will not be able to login
                      again.
                    </p>
                  </li>
                  <li>
                    <h6 className="text-start">Future Registration Option</h6>
                    <p className="text-start">
                      You are welcome to register again in the future, should
                      you decide to return. We will be happy to have you back!
                    </p>
                  </li>
                  <li>
                    <h6 className="text-start">Support Contact</h6>
                    <p className="text-start">
                      If you have any questions or need assistance during this
                      process, please contact us at{" "}
                      <a
                        className="cursor-pointer link-underline-primary"
                        onClick={this.handleEmailClick}
                        style={{ cursor: "pointer" }}
                      >
                        {emailAddress}
                      </a>
                      .
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="center-flex">
            <div
              className="acn-del center-flex"
              style={{ flexDirection: "column", marginTop: "50px" }}
            >
              <div
                className="card-header-d w-100"
                // style={{ background: "var(--primary-color-dark-theme)" }}
              >
                <h3 className="text-center">Account Delete</h3>
              </div>
              <div className="form-signin w-100">
                <AlertCompo show={pageAlert} type={alertType} msg={alertMsg} />
                <div>
                  <div className="flex mt-2">
                    <select
                      name="deletion_reason"
                      id="deletion_reason"
                      value={selectedReason}
                      onChange={this.handleChange}
                      style={{ borderRadius: "5px", padding: "3px" }}
                    >
                      <option value="">Select reason</option>
                      <option value="no_longer_needed">No longer needed</option>
                      <option value="privacy_concerns">Privacy concerns</option>
                      <option value="too_expensive">Too expensive</option>
                      <option value="customer_service_issues">
                        Customer service issues
                      </option>
                      <option value="account_compromised">
                        Account compromised
                      </option>
                      <option value="technical_issues">Technical issues</option>
                      <option value="limited_product_selection">
                        Limited product selection
                      </option>
                      <option value="inconvenience">Inconvenience</option>
                      <option value="found_better_alternative">
                        Found a better alternative
                      </option>
                      <option value="no_recent_activity">
                        No recent activity
                      </option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                  <div className="my-2">
                    <textarea
                      placeholder="Please justify in details"
                      style={{
                        padding: "4px",
                        borderRadius: "5px",
                        width: "100%",
                        height: "100px",
                      }}
                      value={description}
                      onChange={this.handleDescChange}
                      onBlur={() => {
                        if (description.length < 100) {
                          this.setAlertFn(
                            "warning",
                            "Description should be of 100 characters",
                            true
                          );
                        } else {
                          this.setAlertFn("", "", false);
                        }
                      }}
                    ></textarea>
                    {100 - description.length > 0 && (
                      <div className="d-ex-small-font-mufama text-end">
                        Characters remaining: {100 - description.length}
                      </div>
                    )}
                  </div>
                  <div
                    className="w-100 pb-1"
                    style={{ color: "#fff", whiteSpace: "nowrap" }}
                  >
                    <i className="fa-solid fa-key mx-1 me-2"></i>
                    <span className="pass" style={{ fontSize: "18px" }}>
                      Password
                    </span>
                  </div>
                  <div style={{ position: "relative", width: "100%" }}>
                    <input
                      type={flag ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      className="d-input"
                      onChange={(e) => {
                        this.setState({
                          pageAlert: false,
                          password: e.target.value,
                        });
                      }}
                    />
                    <div
                      className="input-group-prepend mt-1 cursor-pointer"
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "3%",
                        zIndex: 3,
                      }}
                    >
                      <i
                        style={{ color: "black" }}
                        className={showpassword}
                        aria-hidden="true"
                        onClick={this.hideshowpassword}
                      ></i>
                    </div>
                  </div>
                </div>
                <div className="mt-3 form-group text-center">
                  <button
                    className="btn btn-warning"
                    style={{
                      width: "100px",
                      fontWeight: "600",
                    }}
                    onClick={this.sendAccountDeleteRequest}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

export default AccountDeletePage;
