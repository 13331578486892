import React, { Component } from "react";
import axios from "axios";
import WithNavigate from "./withNavigate";

class SelectedPolicyPage extends Component {
  state = {
    record: "",
    html: "",
    loca: this.props.loca,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.updateSearchParams();
  }

  updateSearchParams = () => {
    const { location } = this.props;

    if (location.state) {
      const searchParams = new URLSearchParams(location.state);
      let { state } = location.state;
      if (state) {
        let data = state.data;
        console.log("data: ", data);

        let html_val = data.html.replace(/\\n|\\/g, "");
        this.setState({ record: data, html: html_val });
      }
    } else {
      // let id = searchParams.get("id");
      let id = new URLSearchParams(location.search).get("id");
      if (id) {
        this.getPolicyData(id);
      }
    }
  };

  getPolicyData = (id) => {
    var token = localStorage.getItem("token");
    axios
      .get(this.state.loca + `/loom/get/selected/knowledge/${id}`, {
        headers: {
          // authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        const respData = resp.data;
        console.log("respData", respData);
        let html_val = respData.knowledge.html.replace(/\\n|\\/g, "");
        this.setState({ record: respData.knowledge, html: html_val });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    // if (!(this.state.record = "")) {
    return (
      <>
        {console.log("record", this.state.record)}

        <div>
          <div
            className="d-flex align-items-center mt-2 mb-4 border-bottom"
            style={{ justifyContent: "start", width: "100%" }}
          >
            <div className="new-navbar-logo ms-2 text-start">
              <span style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                LoomYarn
              </span>
              {/* <NavLink
                  onClick={() => setOverlay(false)}
                  className="navbar-brand cursor-pointer"
                  to="/"
                >
                  <img
                    className="mufama-logo mb-2"
                    src={imageloca + appLogo}
                    alt="Mufama Logo"
                  />
                </NavLink> */}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                wordWrap: "break-word",
              }}
            >
              <div style={{ width: "85%" }} className="center-flex mb-2">
                <h3>{this.state.record.title}</h3>
              </div>
              <div style={{ width: "80%" }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.html,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
    // }
  }
}

export default WithNavigate(SelectedPolicyPage);
