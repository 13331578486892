import axios from "axios";
import React, { Component } from "react";
import WorkInProgress from "./work_in_progress";

class KnowledgeCompo extends Component {
  state = {
    loca: this.props.loca,
    loading: false,
    page_error: false,
    error: false,
    title: "",
    html: "",
    records: [],
  };

  constructor(props) {
    super(props);
    this.tableName = React.createRef();
    this.catId = React.createRef();
    this.baseId = React.createRef();
    this.knowledgeName = React.createRef();
  }

  componentDidMount() {
    // if (location) {
    //   if (location.knowledge.knowledge !== "") {
    //     // setTitle(location.knowledge.knowledge.title);
    //     // setHtml(location.knowledge.knowledge.html);
    //     let arr = [];
    //     arr.push(location.knowledge.knowledge);
    //     setRecords(arr);
    //     setLoading(false);
    //   } else {
    //     getKnowledge();
    //   }
    // } else {
    //   catId.current = searchParam.get("categoryid");
    //   baseId.current = searchParam.get("baseid");
    //   knowledgeName.current = searchParam.get("name");
    //   getKnowledgeByCategoryAndBase();
    // }

    // return () => {
    //   setPageError(false);
    //   setError("");
    // };
  }

  getKnowledge = () => {
    // this.tableName.current = searchParam.get("tableName");
    var token = localStorage.getItem("token");
    this.setState({ loading: true });
    if (this.tableName.current) {
      axios
        .get(
          this.state.loca + "/loom/get/knowledge/" + this.tableName.current,
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        )
        .then(
          (resp) => {
            const rvrecord = resp.data.knowledge;
            console.log(resp, rvrecord);
            if ("Error" in rvrecord) {
              this.setState({
                page_error: false,
                error: rvrecord.error,
                loading: false,
              });
            } else {
              let arr = [];
              arr.push(rvrecord.knowledge);
              this.setState({ loading: false, records: arr });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.setState({
        page_error: true,
        error: "Table Name not Found",
        loading: false,
      });
      return;
    }
  };

  getKnowledgeByCategoryAndBase = () => {
    this.setState({ page_error: false, error: "", loading: true });
    var token = localStorage.getItem("token");
    if (
      this.catId.current &&
      this.baseId.current &&
      this.knowledgeName.current
    ) {
      axios
        .get(
          this.state.loca +
            "/loom/get/knowledge/" +
            this.catId.current +
            "/" +
            this.baseId.current +
            "/" +
            this.knowledgeName.current,
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        )
        .then(
          (resp) => {
            const rvrecord = resp.data;
            console.log(rvrecord);
            if ("Error" in rvrecord) {
              this.setState({
                page_error: true,
                error: rvrecord.Error,
                loading: false,
              });
            } else {
              // setHtml(rvrecord.knowledge.html);
              // setTitle(rvrecord.knowledge.title);
              // let arr = [];
              // arr.push(rvrecord.knowledge);
              this.setState({ loading: false, records: rvrecord.knowledge });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else if (this.catId.current && this.baseId.current) {
      axios
        .get(
          this.state.loca +
            "/loom/get/knowledge/" +
            this.catId.current +
            "/" +
            this.baseId.current,
          {
            headers: {
              authorization: "Bearer " + token,
            },
          }
        )
        .then(
          (resp) => {
            const rvrecord = resp.data;
            if ("Error" in rvrecord) {
              this.setState({
                page_error: true,
                error: rvrecord.Error,
                loading: false,
              });
            } else {
              // setHtml(rvrecord.knowledge.html);
              // setTitle(rvrecord.knowledge.title);
              this.setState({ loading: false, records: rvrecord.knowledge });
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.setState({
        page_error: true,
        error: "Please give correct categoryid , baseid and name",
        loading: false,
      });
      return;
    }
  };

  render() {
    return (
      <div>
        {this.state.loading === true ? (
          <div>
            <div className="g-compo-loader2">
              <WorkInProgress></WorkInProgress>
            </div>
          </div>
        ) : (
          <div className="pagesetup">
            {this.state.page_error && (
              <div className="alert alert-danger">{this.state.error}</div>
            )}
            {this.state.records.length > 0 &&
              this.state.records.map((obj, ind) => (
                <div key={ind}>
                  <h1 className=" text-center">{obj.title}</h1>
                  <div className="container justify-content-center">
                    <div
                      className=" card card-body"
                      dangerouslySetInnerHTML={{ __html: obj.html }}
                    ></div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }
}

export default KnowledgeCompo;
