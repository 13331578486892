import React, { Component } from "react";

class AlertCompo extends Component {
  render() {
    const { show, type, msg } = this.props;

    if (show) {
      return (
        <div className={`alert alert-${type} m-0 p-1`} role="alert">
          {msg}
        </div>
      );
    }

    return null;
  }
}

export default AlertCompo;
